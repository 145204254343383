@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap);
body {
  margin: 0;
  padding: 0;
}

body div,
body span,
body p,
body button {
  font-family: "Nunito", sans-serif !important;
}

.header {
  background-color: #f8f8f8;
}

body .logo {
  color: #3256d3;
  font-family: "Archivo Black", sans-serif !important;
  font-size: 36px;
}

